import React, { useState } from 'react';

function Modal(props) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div>
      <div className={isActive ? 'is-active modal' : 'modal'}>
        <div class="modal-background"></div>
        <div class="modal-content">
          <div className='speaker-info'>
            <img src={props.img} alt={props.speakerName} />
            <button
            class="modal-close is-large mobile"
            aria-label="close"
            onClick={toggleClass}
            ></button>
            <div>
                <h2>{props.speakerName}</h2>
                <h3>{props.speakerCountry}</h3>
                <p>{props.speakerBio}</p>
            </div>
          </div>
          <div className='session-info'>
            <div>
              <h3>{props.sessionType}</h3>
              <p>{props.sessionName}</p>
            </div>
            <button
            class="modal-close is-large desktop"
            aria-label="close"
            onClick={toggleClass}
            ></button>
          </div>
        </div>
      </div>
      <div onClick={toggleClass} className='speaker-box' role="button" aria-label="Speaker information" name="Speaker information" aria-expanded="false">
        <div className='gradient'></div>
        <img src={props.img} alt={props.speakerName} />
        <h2>{props.speakerName}</h2>
      </div>
    </div>
  );
}

export default Modal;
