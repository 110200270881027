/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { setSourceCookie } from '@bgea-js/react-utilities';

import Seo from '../components/seo';
import PageStyle from './pagestyle';
import Footer from './footer';
import Modal from './Modal';
import EuroHeader from './EuroHeader';
import AugustDetails from './AugustDetails';
import NewFooter from './newfooter';
import './layout.scss';

const KalturaVideo = loadable(() => import('./KalturaVideo'));

const shortcodes = {
  KalturaVideo,
  Modal,
  EuroHeader,
  AugustDetails,
};

const Layout = ({ children, meta, url, styles, useNewFooter = false }) => {
  useEffect(() => {
    setSourceCookie();
    try {
      window.Typekit.load({ async: true });
    } catch (e) {}
  }, []);
  return (
    <MDXProvider components={shortcodes}>
      {meta && (
        <Seo
          title={meta.title}
          description={meta.description}
          socialTitle={meta.socialTitle}
        />
      )}
      <PageStyle meta={meta} styles={styles} />
      <React.Fragment>{children}</React.Fragment>
      {!useNewFooter ? <Footer /> : <NewFooter />}
    </MDXProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
