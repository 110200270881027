import * as React from 'react';
// import PropTypes from "prop-types"
// import { Link } from "gatsby"
import BGEALogo from '../images/bgea.svg';
import './footer.scss';

const Footer = ({ menuLinks }) => {
  return (
    <footer className="new-footer">
      <section className="widget_text widget custom_html-3 widget_custom_html">
        <div className="textwidget custom-html-widget">
          <div className="columns">
            <div className="column logo-column">
              <a href="https://billygraham.org" target="_blank" rel="noreferrer">
                <img
                  src={BGEALogo}
                  alt="Billy Graham Evangelistic Association"
                  width="141"
                  height="60"
                />
              </a>
            </div>
            <div className="column">
              <p>
                <a href="https://billygraham.org/" target="_blank" rel="noreferrer">
                  A ministry of The Billy Graham Evangelistic Association
                </a>
                <br />© {new Date().getFullYear()} | BGEA is a registered
                501(c)(3) non-profit organization. |{' '}
                <a
                  href="https://billygraham.org/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
